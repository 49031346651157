import React from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import style from "./style";
import { structuredTextPropTypes } from "../../utils/prop-types";
import CustomLink from "../../components/CustomLink";
import { motion } from "framer-motion";
import { vPulseIn, vSlideRightFade, vTextFade } from "../../utils/animations";

const TextBlueBackground = ({ slice, classes }) => {
  const { primary } = slice;

  const viewport = {
    once: true,
    margin: "-6%",
  };

  return (
    <div className={classes.transparency}>
      <motion.div
        variants={vTextFade}
        initial={"offscreen"}
        whileInView={"onscreen"}
        viewport={viewport}
        transition={{
          type: "tween",
          delay: 0.5,
          duration: 0.8,
        }}
      >
        {RichText.render(primary.title)}
        <Typography variant="subtitle1" className="subtitle">
          {RichText.asText(primary.subtitle)}
        </Typography>
      </motion.div>

      <motion.div
        variants={vTextFade}
        initial={"offscreen"}
        whileInView={"onscreen"}
        viewport={viewport}
        transition={{
          type: "tween",
          delay: 0.8,
          duration: 0.8,
        }}
      >
        <Typography variant="subtitle1" className="description">
          {RichText.asText(primary.description)}
        </Typography>
      </motion.div>
      <motion.div
        variants={vSlideRightFade}
        initial={"offscreen"}
        whileInView={"onscreen"}
        viewport={viewport}
      >
        <Typography variant="subtitle1" className="highlightedText">
          {RichText.asText(primary.highlightedText)}
        </Typography>
      </motion.div>
      <CustomLink link={primary.ctaButtonLink}>
        <motion.div
          variants={vPulseIn}
          initial={"offscreen"}
          whileInView={"onscreen"}
          viewport={viewport}
        >
          <Button variant="contained" color="primary" className="cta">
            {RichText.asText(primary.ctaButtonText)}
          </Button>
        </motion.div>
      </CustomLink>
    </div>
  );
};

TextBlueBackground.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      title: structuredTextPropTypes,
      subtitle: structuredTextPropTypes,
      description: structuredTextPropTypes,
      highlightedText: structuredTextPropTypes,
      ctaButtonText: structuredTextPropTypes,
      ctaButtonLink: PropTypes.objectOf(PropTypes.any),
    }).isRequired,
    items: PropTypes.array.isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(TextBlueBackground);
